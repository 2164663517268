<script setup lang="ts">
import { mdiWhatsapp } from "@mdi/js";

const { t } = useI18n();

const contactDetails = {
  whatsapp: "+966 58 067 2375",
  whatsappLink: "https://wa.me/966580672375",
};
</script>

<template>
  <MBtn
    color="default"
    variant="text"
    :href="contactDetails.whatsappLink"
    target="_blank"
  >
    <MIcon
      start
      :icon="mdiWhatsapp"
    />
    <span class="me-2 inline-block">
      {{ t("support") }}
    </span>
    <span dir="ltr">{{ contactDetails.whatsapp }}</span>
  </MBtn>
</template>
